export const helpIconSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <circle cx="12" cy="12" r="10.5" fill="#FF6000"/>
  <rect x="10.8281" y="10.8281" width="2.34375" height="5.85938" fill="white"/>
  <rect x="10.8281" y="7.3125" width="2.34375" height="2.34375" fill="white"/>
</svg>`

export const deliveryForBusinessSvg = `
<svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.3307 10.2257H18.9841V13.9861L17.6455 13.1518L16.3069 13.9861V10.2257H12.9603C12.5922 10.2257 12.291 10.5077 12.291 10.8524V18.3733C12.291 18.718 12.5922 19 12.9603 19H22.3307C22.6988 19 23 18.718 23 18.3733V10.8524C23 10.5077 22.6988 10.2257 22.3307 10.2257ZM6.2672 8.77431H15.6376C16.0057 8.77431 16.3069 8.49227 16.3069 8.14757V0.626736C16.3069 0.282031 16.0057 0 15.6376 0H12.291V3.76042L10.9524 2.92607L9.61376 3.76042V0H6.2672C5.89907 0 5.59788 0.282031 5.59788 0.626736V8.14757C5.59788 8.49227 5.89907 8.77431 6.2672 8.77431ZM10.0397 10.2257H6.69312V13.9861L5.3545 13.1518L4.01587 13.9861V10.2257H0.669312C0.30119 10.2257 0 10.5077 0 10.8524V18.3733C0 18.718 0.30119 19 0.669312 19H10.0397C10.4078 19 10.709 18.718 10.709 18.3733V10.8524C10.709 10.5077 10.4078 10.2257 10.0397 10.2257Z" fill="#FF6000"/>
</svg>`

export const certificatesSvg = `
<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.25 0V4.046C9.68664 3.50687 8.9355 3.20827 8.15575 3.21348C7.376 3.21869 6.62892 3.5273 6.07282 4.07392C5.51671 4.62053 5.19529 5.36219 5.17666 6.14173C5.15802 6.92128 5.44365 7.67744 5.973 8.25H0.5V2.25C0.5 1.65326 0.737053 1.08097 1.15901 0.65901C1.58097 0.237053 2.15326 0 2.75 0H10.25ZM11.75 0V4.011C12.3196 3.48675 13.0698 3.20297 13.8438 3.21901C14.6178 3.23504 15.3556 3.54964 15.903 4.09704C16.4504 4.64444 16.765 5.38225 16.781 6.15622C16.797 6.93019 16.5133 7.6804 15.989 8.25H21.5V2.25C21.5 1.65326 21.2629 1.08097 20.841 0.65901C20.419 0.237053 19.8467 0 19.25 0H11.75ZM21.5 9.75H12.517C12.6106 10.7749 13.0838 11.7277 13.8439 12.4215C14.6039 13.1154 15.5959 13.5 16.625 13.5C16.8239 13.5 17.0147 13.579 17.1553 13.7197C17.296 13.8603 17.375 14.0511 17.375 14.25C17.375 14.4489 17.296 14.6397 17.1553 14.7803C17.0147 14.921 16.8239 15 16.625 15C15.6365 15.0004 14.6654 14.7402 13.8095 14.2456C12.9537 13.7511 12.2433 13.0396 11.75 12.183V18H19.25C19.8467 18 20.419 17.7629 20.841 17.341C21.2629 16.919 21.5 16.3467 21.5 15.75V9.75ZM10.25 18V12.183C9.75672 13.0396 9.04635 13.7511 8.19048 14.2456C7.33461 14.7402 6.36349 15.0004 5.375 15C5.17609 15 4.98532 14.921 4.84467 14.7803C4.70402 14.6397 4.625 14.4489 4.625 14.25C4.625 14.0511 4.70402 13.8603 4.84467 13.7197C4.98532 13.579 5.17609 13.5 5.375 13.5C6.40405 13.4998 7.39588 13.1151 8.15589 12.4213C8.9159 11.7275 9.38922 10.7748 9.483 9.75H0.5V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18H10.25Z" fill="#FF6000"/>
<path d="M10.085 7.35409C10.115 7.65109 10.123 7.92909 10.121 8.15909C9.85224 8.16156 9.58351 8.14954 9.31602 8.12309C8.48302 8.03909 7.63902 7.79809 7.12102 7.28009C6.84792 6.99706 6.6969 6.61808 6.7005 6.22479C6.7041 5.83149 6.86204 5.45535 7.14028 5.17736C7.41852 4.89938 7.79482 4.74181 8.18812 4.73858C8.58142 4.73535 8.96025 4.88672 9.24302 5.16009C9.76002 5.67709 10.002 6.52009 10.085 7.35409ZM11.877 7.35409C11.847 7.65109 11.839 7.92909 11.841 8.15909C12.071 8.16109 12.349 8.15309 12.646 8.12309C13.479 8.03909 14.323 7.79809 14.841 7.28009C15.1094 6.99626 15.2564 6.61894 15.2509 6.22838C15.2454 5.83782 15.0877 5.46481 14.8114 5.18868C14.5352 4.91255 14.1621 4.75507 13.7715 4.74973C13.381 4.74439 13.0037 4.89161 12.72 5.16009C12.202 5.67809 11.96 6.52209 11.877 7.35409Z" fill="#FF6000"/>
</svg>`
