import config from 'config'
import { SearchQuery } from 'storefront-query-builder';
import store from '@vue-storefront/core/store'

export function baseFilterProductsQuery (
  parentCategory,
  filters = [],
  toSkipCat = [],
  availability = true
) { // TODO add aggregation of color_options and size_options fields
  let searchProductQuery = new SearchQuery()
  searchProductQuery = searchProductQuery
    .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } }) /* 2 = disabled, 4 = out of stock */

  if (config.products.listOutOfStockProducts === false && !availability) {
    searchProductQuery = searchProductQuery.applyFilter({ key: 'stock.is_in_stock', value: { 'eq': true } })
  }
  // Add available catalog filters
  for (const attrToFilter of filters) {
    searchProductQuery = searchProductQuery.addAvailableFilter({ field: attrToFilter, scope: 'catalog' })
  }

  const isBrandCategory = store.getters['category-extension/getIsBrandCategory'] && +parentCategory.pim_brand_id

  const childCats = [isBrandCategory ? parentCategory?.pim_brand_id : parentCategory?.id]
  if (parentCategory && parentCategory.children_data) {
    const recurCatFinderBuilder = (category) => {
      if (!category) {
        return
      }

      if (!category.children_data) {
        return
      }

      for (const sc of category.children_data) {
        if (sc && sc.id) {
          childCats.push(sc.id)
        }
        recurCatFinderBuilder(sc)
      }
    }
    recurCatFinderBuilder(parentCategory)
  }

  const cats = childCats.filter(i => !toSkipCat.includes(i)).filter(Boolean)

  if (!cats.length) return searchProductQuery

  searchProductQuery = searchProductQuery.applyFilter({ key: isBrandCategory ? 'pim_brand_id' : 'category_ids', value: { 'in': cats } })

  return searchProductQuery
}

export function buildFilterProductsQuery (currentCategory, chosenFilters = {}, defaultFilters = null) {
  let filterQr = baseFilterProductsQuery(currentCategory, defaultFilters == null ? config.products.defaultFilters : defaultFilters)

  // add choosedn filters
  for (const code of Object.keys(chosenFilters)) {
    const filter = chosenFilters[code]
    const attributeCode = Array.isArray(filter) ? filter[0].attribute_code : filter.attribute_code

    if (Array.isArray(filter) && attributeCode !== 'price') {
      const values = filter.map(filter => filter.id)
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'in': values }, scope: 'catalog' })
    } else if (attributeCode !== 'price') {
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'eq': filter.id }, scope: 'catalog' })
    } else { // multi should be possible filter here?
      const rangeqr = {}
      const filterValues = Array.isArray(filter) ? filter : [filter]
      filterValues.forEach(singleFilter => {
        if (singleFilter.from) rangeqr['gte'] = singleFilter.from
        if (singleFilter.to) rangeqr['lte'] = singleFilter.to
      })
      filterQr = filterQr.applyFilter({ key: attributeCode, value: rangeqr, scope: 'catalog' })
    }
  }

  filterQr = filterQr.applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })

  return filterQr
}

export function prepareRelatedQuery (key, sku) {
  let relatedProductsQuery = new SearchQuery()

  relatedProductsQuery = relatedProductsQuery.applyFilter({ key: key, value: { 'in': sku } })

  relatedProductsQuery = relatedProductsQuery
    .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [1] } })

  if (config.products.listOutOfStockProducts === false) {
    relatedProductsQuery = relatedProductsQuery.applyFilter({ key: 'stock.is_in_stock', value: { 'eq': true } })
  }

  return relatedProductsQuery
}
