const timeZone = 'Europe/Kyiv';

export const endOfYesterday = (() => {
  try {
    const now = new Date();

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    const [{ value: month }, , { value: day }, , { value: year }] = formatter.formatToParts(now);

    const kyivMidnight = new Date(`${year}-${month}-${day}T00:00:00`);

    return kyivMidnight.getTime() - 1;
  } catch (e) {
    return new Date().setHours(0, 0, 0, 0) - 1;
  }
})();

export const checkIfDateExpired = (date: string) => {
  if (!date) return true;

  return new Date(date).getTime() <= endOfYesterday;
};
