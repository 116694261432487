import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from 'theme/store/catalog'
import { CategoryModule } from '$modules/category'
import { ProductModule } from '$modules/product';
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CheckoutModule } from 'theme/store/checkout'
import { BlogModule } from 'theme/store/blog'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from 'theme/store/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { NewsletterModule } from 'theme/store/newsletter'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { SearchResultModule } from '../store/search-result'
import { CategoryExtensionModule } from 'theme/store/category-extension'
import { RecentlyViewedModule } from 'theme/store/recently-viewed'
import { WishlistModule } from 'theme/store/wishlist'
import { BannerModule } from '$modules/banner'
import { UserCommunicationModule } from 'theme/store/user-communication'
import { PaymentModule } from 'theme/store/payment'
import { UserPaymentCardsModule } from 'theme/store/user-payment-cards'
import { CartModule } from 'theme/store/cart'
import { ConfigVarusModule } from '$modules/config-varus'
import { BonusCardModule } from 'theme/store/bonusCard'
import { PromotionModule } from '$modules/promotion'
import { TendersModule } from 'theme/store/tenders'
import { ProductReviewModule } from 'theme/store/product-review'
import { SpecialOffersModule } from '$modules/special-offers';
import { PromoOnlyModule } from '$modules/promo-only';
import { BrandsModule } from '$modules/brands';
import { BrandModule } from '$modules/brand';
// import { VarusPerfectModule } from '$modules/varus-perfect';
import { FAQModule } from '$modules/faq';
import { VarusCafeModule } from '$modules/varus-cafe';
import { InterestingCategoriesModule } from '$modules/interesting-categories';

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules (fromCache: any = {}) {
  registerModule((ctx) => UrlModule(ctx, fromCache))
  registerModule((ctx) => CategoryModule(ctx, fromCache))
  registerModule((ctx) => CatalogModule(ctx, fromCache))
  registerModule((ctx) => CheckoutModule(ctx, fromCache)) // To Checkout
  registerModule((ctx) => CartModule(ctx, fromCache))
  registerModule((ctx) => PaymentModule(ctx, fromCache))
  registerModule((ctx) => NotificationModule(ctx, fromCache))
  registerModule((ctx) => UserModule(ctx, fromCache)) // Trigger on user icon click
  registerModule((ctx) => CatalogNextModule(ctx, fromCache))
  registerModule((ctx) => BreadcrumbsModule(ctx, fromCache))
  registerModule((ctx) => CmsModule(ctx, fromCache))
  registerModule((ctx) => NewsletterModule(ctx, fromCache))
  registerModule((ctx) => CategoryExtensionModule(ctx, fromCache))
  registerModule((ctx) => SearchResultModule(ctx, fromCache));
  registerModule((ctx) => RecentlyViewedModule(ctx, fromCache))
  registerModule((ctx) => WishlistModule(ctx, fromCache))
  registerModule((ctx) => BannerModule(ctx, fromCache))
  registerModule((ctx) => UserCommunicationModule(ctx, fromCache))
  registerModule((ctx) => UserPaymentCardsModule(ctx, fromCache))
  registerModule((ctx) => ConfigVarusModule(ctx, fromCache))
  registerModule((ctx) => BonusCardModule(ctx, fromCache))
  registerModule((ctx) => PromotionModule(ctx, fromCache))
  registerModule((ctx) => BlogModule(ctx, fromCache))
  registerModule((ctx) => BrandsModule(ctx, fromCache))
  registerModule((ctx) => BrandModule(ctx, fromCache))
  registerModule((ctx) => TendersModule(ctx, fromCache))
  registerModule((ctx) => ProductReviewModule(ctx, fromCache))
  registerModule((ctx) => SpecialOffersModule(ctx, fromCache))
  registerModule((ctx) => PromoOnlyModule(ctx, fromCache))
  registerModule((ctx) => FAQModule(ctx, fromCache))
  registerModule((ctx) => ProductModule(ctx, fromCache))
  // registerModule((ctx) => VarusPerfectModule(ctx, fromCache))
  registerModule((ctx) => VarusCafeModule(ctx, fromCache))
  registerModule((ctx) => InterestingCategoriesModule(ctx, fromCache))
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
]
