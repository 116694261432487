import { setupMultistoreRoutes, localizedRoute } from '@vue-storefront/core/lib/multistore';
import App from './App';
import routes from './router';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import VueMq from 'vue-mq';
// import '@vue-storefront/core/lib/passive-listeners';
import { once } from '@vue-storefront/core/helpers';
import { breakpoints } from 'theme/helpers/breakpoints';
import VueCookies from 'vue-cookies'
import { claimsStore } from 'theme/store/claims';
import { homepageStore } from 'theme/store/homepage';
import { uiStore } from 'theme/store/ui';
import { promotedStore } from 'theme/store/promoted-offers';
import { defaultContentStore } from 'theme/store/default-content';
import { BlogStore } from 'theme/store/blog';
import { VarusUserStore } from 'theme/store/userInfo';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { siteDataStore } from 'theme/store/site-data';
import * as themeFilters from './filters';
import i18n from '@vue-storefront/i18n';
import { ImagePhotoGallery } from 'theme/store/photogallery';
import VueGtm from '@gtm-support/vue2-gtm';
import { GiftCardsStore } from 'theme/store/gift-cards';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
  Vue.use(VueMq, { breakpoints });
  Vue.use(VueCookies);
});

Object.keys(themeFilters).forEach(key => {
  Vue.filter(key, themeFilters[key]);
});

const themeEntry = App;
function initTheme (app, router, store, config, ctx) {
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes, 10);

  StorageManager.init('claims');

  const fromCache = ctx?.fromCache

  if (fromCache) {
    claimsStore.state = fromCache?.claims || claimsStore.state
    homepageStore.state = fromCache?.homepage || homepageStore.state
    uiStore.state = fromCache?.ui || uiStore.state
    promotedStore.state = fromCache?.promoted || promotedStore.state
    defaultContentStore.state = fromCache?.defaultContent || defaultContentStore.state
    VarusUserStore.state = fromCache?.userInfo || VarusUserStore.state
    siteDataStore.state = fromCache?.siteData || siteDataStore.state
    ImagePhotoGallery.state = fromCache?.photoGallery || ImagePhotoGallery.state
    BlogStore.state = fromCache?.blog || BlogStore.state
    GiftCardsStore.state = fromCache?.giftCard || GiftCardsStore.state
  }

  store.registerModule('claims', claimsStore);
  store.registerModule('homepage', homepageStore);
  store.registerModule('ui', uiStore);
  store.registerModule('promoted', promotedStore);
  store.registerModule('defaultContent', defaultContentStore);
  store.registerModule('userInfo', VarusUserStore);
  store.registerModule('siteData', siteDataStore);
  store.registerModule('photoGallery', ImagePhotoGallery);
  store.registerModule('blog', BlogStore);
  store.registerModule('giftCard', GiftCardsStore);

  once('__VUE_EXTEND__', () => {
    Vue.use(VueGtm, {
      id: `GTM-${config.gtm.key}`,
      defer: true,
      enabled: true,
      debug: false,
      loadScript: false,
      vueRouter: router,
      trackOnNextTick: false
    });
  })

  const redirectToHomePage = ['my-account', 'ru-my-account']

  store.subscribeAction(action => {
    if (action.type === 'user/logout' && store.getters['user/isLoggedIn']) {
      store.dispatch('userInfo/clearAvatar');

      if (action.payload && action.payload.silent) {
        if (redirectToHomePage.includes(router?.currentRoute?.name)) {
          router.push(localizedRoute(formatRegionUrl('/')));
        }

        store.dispatch('notification/spawnNotification', {
          type: 'success',
          hideIcon: true,
          message: i18n.t('Your session has expired, please log in again'),
          action1: { label: i18n.t('OK') }
        });
      }
    }
  })
}

export { themeEntry, initTheme };
